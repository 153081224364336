import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import { InsufficientFunds } from "components";
import { INSUFFICIENT_FUNDS } from "constants";
import { UserContext } from "context";
import {
    capitalize,
    fetch,
    formatBoolean,
    formatCredits,
    formatDate,
    formatNumber,
    formatPercent,
    parseErrorCode,
    parseErrorResponse,
    pluralize,
    post,
} from "functions";
import { Loading } from "library";
import { API_URL } from "settings";

import {
    percentCompleted,
    progressColor,
    showDownloadButton,
    showRetryButton,
    showStartButton,
} from "./utils";

const INITIAL_TIMEOUT_INTERVAL = 3000;

function Status({ status }) {
    return (
        <span className="badge badge-dot me-4">
            <i className={`bg-${progressColor(status)}`} />
            <span className="text-dark text-xs">{capitalize(status)}</span>
        </span>
    );
}

function Progress({ skipTrace }) {
    const color = progressColor(skipTrace.status);
    const percent = percentCompleted(skipTrace);

    const detail = [];
    if (skipTrace.tasks_success) {
        detail.push(
            `${formatNumber(skipTrace.tasks_success)} ${pluralize(
                "hit",
                skipTrace.tasks_success,
            )}`,
        );
    }
    if (skipTrace.tasks_nodata) {
        detail.push(
            `${formatNumber(skipTrace.tasks_nodata)} ${pluralize(
                "miss",
                skipTrace.tasks_nodata,
            )}`,
        );
    }
    if (skipTrace.tasks_failure) {
        detail.push(
            `${formatNumber(skipTrace.tasks_failure)} ${pluralize(
                "error",
                skipTrace.tasks_failure,
            )}`,
        );
    }

    return (
        <div className="progress-wrapper">
            <div className="progress-info">
                <div className="progress-percentage">
                    <span className="text-xs">
                        {formatPercent(percent)}{" "}
                        {detail.length > 0 ? `(${detail.join(" / ")})` : ""}
                    </span>
                </div>
            </div>
            <div className="progress w-auto">
                <div
                    className={`progress-bar bg-gradient-${color}`}
                    role="progressbar"
                    aria-valuenow={percent}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${percent}%` }}
                ></div>
            </div>
        </div>
    );
}

export default function SkipTraceDetail() {
    let { id } = useParams();
    const { fetchWallet } = useContext(UserContext);
    const [skipTrace, setSkipTrace] = useState();
    const [loading, setLoading] = useState(false);
    const [errorCode, setErrorCode] = useState();

    useEffect(() => {
        var timeoutID;
        var updatedAt;

        let timeoutInterval = INITIAL_TIMEOUT_INTERVAL;

        function refresh() {
            fetchWallet();
            fetch(`/api/skiptrace/${id}`)
                .then((data) => {
                    setSkipTrace(data);

                    // Stop refreshing if it completed
                    if (data.status === "success") {
                        return;
                    }

                    const changed = updatedAt != data.updated_at;

                    if (changed) {
                        timeoutInterval = INITIAL_TIMEOUT_INTERVAL;
                    } else {
                        timeoutInterval = Math.round(timeoutInterval * 1.6);
                    }

                    timeoutID = setTimeout(refresh, timeoutInterval);
                    updatedAt = data.updated_at;
                })
                .catch((xhr) => {
                    toast.error(parseErrorResponse(xhr));
                });
        }

        refresh();

        return () => {
            if (timeoutID) {
                clearTimeout(timeoutID);
            }
        };
    }, [fetchWallet, id]);

    const start = async () => {
        setLoading(true);
        setErrorCode();
        try {
            const data = await post(`/api/skiptrace/${skipTrace.id}/start/`);
            setSkipTrace(data);
        } catch (xhr) {
            const errorCode = parseErrorCode(xhr);
            if (errorCode !== INSUFFICIENT_FUNDS) {
                toast.error(parseErrorResponse(xhr));
            }
            setErrorCode(errorCode);
        }
        setLoading(false);
    };

    if (!skipTrace) {
        return <Loading />;
    }

    const lineItems = [
        { text: "File Name", value: skipTrace.input_csv_file_name },
        {
            text: "Total Number of Rows",
            value: formatNumber(skipTrace.row_count),
        },
        {
            text: "Scrub Duplicates",
            value: formatBoolean(skipTrace.scrub_duplicates),
        },
        {
            text: "Order #",
            value: skipTrace.stripe_reference_id || "(unpaid)",
        },
        {
            text: "Total Skip Cost",
            value: formatCredits(skipTrace.price_total),
        },
        {
            text: "Created Date",
            value: formatDate(skipTrace.created_at),
        },
        {
            text: "Status",
            value: <Status status={skipTrace.status} />,
        },
        {
            text: "Progress",
            value: <Progress skipTrace={skipTrace} />,
        },
        {
            text: "Hit Rate",
            value: formatPercent(skipTrace.hit_rate),
        },
    ];

    const paymentError = skipTrace.stripe_error ? (
        <div className="alert alert-danger font-weight-bold text-white" role="alert">
            <strong>Payment Error:</strong> {skipTrace.stripe_error}
        </div>
    ) : null;

    return (
        <div className="card">
            <div className="card-header pb-0">
                <h5>Skip Tracing Detail</h5>
            </div>
            <div className="card-body">
                {errorCode === INSUFFICIENT_FUNDS ? <InsufficientFunds /> : null}
                {paymentError}
                <table className="table table-borderless align-items-center mb-4 w-50 mx-auto">
                    <tbody>
                        {lineItems.map(({ text, value }) => (
                            <tr key={text}>
                                <td className="text-sm font-weight-bold">{text}:</td>
                                <td className="align-middle text-sm">{value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="d-flex justify-content-center gap-2">
                    {showStartButton(skipTrace) && (
                        <button
                            type="button"
                            className="btn bg-gradient-success"
                            onClick={start}
                            disabled={loading}
                        >
                            Start Skipping
                        </button>
                    )}
                    {showRetryButton(skipTrace) && (
                        <button
                            type="button"
                            className="btn bg-gradient-info"
                            onClick={start}
                            disabled={loading}
                        >
                            Retry
                        </button>
                    )}
                    {showDownloadButton(skipTrace) && (
                        <a
                            className="btn bg-gradient-primary"
                            href={`${API_URL}/api/csv_file/${skipTrace.result_csv}.csv`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Download Results
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
}
