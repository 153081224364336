import React, { useContext, useState, useEffect } from "react";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { UserContext } from "context";
import {
    fetch,
    formatNumber,
    formatPercent,
    parseErrorResponse,
    pluralize,
    post,
    userHasPerm,
    zeroPad,
} from "functions";

import { UsersChart, UsersTable, Scraping, BarChart } from "./components";

const COLOR_COUNT = "#8392ab";
const COLOR_ROWS = "#cb0c9f";
const COLOR_REVENUE = "#fbcf33";
const COLOR_PROFIT = "#82d616";

const BLUE_PALETTE = ["#004c6d", "#6996b3", "#c1e7ff"];

const COUNT_SERIES = {
    label: "Count",
    mapValues: (rows) => rows.map((row) => row.count),
    backgroundColor: COLOR_COUNT,
};
const ROWS_EXPORTED_SERIES = {
    label: "Rows Exported",
    mapValues: (rows) => rows.map((row) => row.rows_exported),
    backgroundColor: COLOR_ROWS,
};
const ROWS_SCRUBBED_SERIES = {
    label: "Rows Scrubbed",
    mapValues: (rows) => rows.map((row) => row.total_scrubbed),
    backgroundColor: COLOR_ROWS,
};
const ROWS_SKIPPED_SERIES = {
    label: "Rows Skipped",
    mapValues: (rows) => rows.map((row) => row.total_skipped),
    backgroundColor: COLOR_ROWS,
};
const REVENUE_SERIES = {
    label: "Revenue",
    mapValues: (rows) => rows.map((row) => row.revenue / 100),
    backgroundColor: COLOR_REVENUE,
};
const PROFIT_SERIES = {
    label: "Profit",
    mapValues: (rows) => rows.map((row) => row.profit / 100),
    backgroundColor: COLOR_PROFIT,
};
const LAND_LOCKED_SCRUBBED = {
    label: "Land Locked Scrubbed",
    mapValues: (rows) => rows.map((row) => row.land_locked_scrubbed),
    backgroundColor: BLUE_PALETTE[0],
};
const WETLANDS_SCRUBBED = {
    label: "Wetlands Scrubbed",
    mapValues: (rows) => rows.map((row) => row.wetlands_scrubbed),
    backgroundColor: BLUE_PALETTE[1],
};
const FLOOD_ZONE_SCRUBBED = {
    label: "Flood Zone Scrubbed",
    mapValues: (rows) => rows.map((row) => row.flood_zone_scrubbed),
    backgroundColor: BLUE_PALETTE[2],
};

export default function AdminDashboard() {
    return (
        <div className="card">
            <div className="card-body">
                <Tabs defaultActiveKey="users" className="mb-4">
                    <Tab eventKey="users" title="Users">
                        <UsersTab />
                    </Tab>
                    <Tab eventKey="reports" title="Reports">
                        <ReportsTab />
                    </Tab>
                    <Tab eventKey="metrics" title="Metrics">
                        <MetricsTab />
                    </Tab>
                    <Tab eventKey="scraping" title="Scraping">
                        <ScrapingTab />
                    </Tab>
                    <Tab eventKey="system" title="System">
                        <SystemTab />
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

function UsersTab() {
    const [users, setUsers] = useState();

    const fetchUsers = async () => {
        try {
            const data = await fetch("/user/users/");
            setUsers(data.users);
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    if (!users) {
        return <>Loading...</>;
    }

    return <UsersTable users={users} refresh_users={fetchUsers} />;
}

function ReportsTab() {
    const [metrics, setMetrics] = useState();

    useEffect(() => {
        // Fetch Land Scrubbing, Skip Tracing, Realtor Report, and User metrics
        const fetchMetrics = async () => {
            try {
                const metrics = await fetch("/api/reporting/revenue/");
                setMetrics(metrics);
            } catch (xhr) {
                toast.error(parseErrorResponse(xhr));
            }
        };

        fetchMetrics();
    }, []);

    if (!metrics) {
        return <>Loading...</>;
    }

    return (
        <Tabs defaultActiveKey="user" className="mb-4">
            <Tab eventKey="user" title="Users">
                <UsersChart users={metrics.user || {}} />
            </Tab>
            <Tab eventKey="land_scrub" title="Land Scrubbing">
                <LandRevenueChart id="land_scrub" data={metrics.land_scrub || {}} />
            </Tab>
            <Tab eventKey="skip_trace" title="Skip Tracing">
                <LandRevenueChart id="skip_trace" data={metrics.skip_trace || {}} />
            </Tab>
            <Tab eventKey="parcel_export" title="Data Platform">
                <div className="d-flex">
                    <LandRevenueChart
                        id="parcel_export"
                        title="Parcel Exports (A-La-Carte)"
                        className="w-50"
                        data={metrics?.parcel_export?.basic?.parcel_export || {}}
                        series={[
                            COUNT_SERIES,
                            REVENUE_SERIES,
                            PROFIT_SERIES,
                            ROWS_EXPORTED_SERIES,
                        ]}
                    />
                    <LandRevenueChart
                        id="parcel_export"
                        title="Parcel Exports (Unlimited)"
                        className="w-50"
                        data={metrics?.parcel_export?.unlimited?.parcel_export || {}}
                        series={[
                            COUNT_SERIES,
                            REVENUE_SERIES,
                            PROFIT_SERIES,
                            ROWS_EXPORTED_SERIES,
                        ]}
                    />
                </div>
                <div className="d-flex">
                    <LandRevenueChart
                        id="parcel_export_land_scrub"
                        title="Land Scrubs (A-La-Carte)"
                        className="w-50"
                        data={metrics?.parcel_export?.basic?.land_scrub || {}}
                        series={[
                            COUNT_SERIES,
                            REVENUE_SERIES,
                            PROFIT_SERIES,
                            ROWS_SCRUBBED_SERIES,
                            LAND_LOCKED_SCRUBBED,
                            WETLANDS_SCRUBBED,
                            FLOOD_ZONE_SCRUBBED,
                        ]}
                    />
                    <LandRevenueChart
                        id="parcel_export_land_scrub"
                        title="Land Scrubs (Unlimited)"
                        className="w-50"
                        data={metrics?.parcel_export?.unlimited?.land_scrub || {}}
                        series={[
                            COUNT_SERIES,
                            REVENUE_SERIES,
                            PROFIT_SERIES,
                            ROWS_SCRUBBED_SERIES,
                            LAND_LOCKED_SCRUBBED,
                            WETLANDS_SCRUBBED,
                            FLOOD_ZONE_SCRUBBED,
                        ]}
                    />
                </div>
                <div className="d-flex">
                    <LandRevenueChart
                        id="parcel_export_skip_trace"
                        title="Skip Traces (A-La-Carte)"
                        className="w-50"
                        data={metrics?.parcel_export?.basic?.skip_trace || {}}
                        series={[
                            COUNT_SERIES,
                            REVENUE_SERIES,
                            PROFIT_SERIES,
                            ROWS_SKIPPED_SERIES,
                        ]}
                    />
                    <LandRevenueChart
                        id="parcel_export_skip_trace"
                        title="Skip Traces (Unlimited)"
                        className="w-50"
                        data={metrics?.parcel_export?.unlimited?.skip_trace || {}}
                        series={[
                            COUNT_SERIES,
                            REVENUE_SERIES,
                            PROFIT_SERIES,
                            ROWS_SKIPPED_SERIES,
                        ]}
                    />
                </div>
            </Tab>
            <Tab eventKey="realtor_report" title="Realtor Report">
                <LandRevenueChart
                    id="realtor_report"
                    data={metrics.realtor_report || {}}
                />
            </Tab>
        </Tabs>
    );
}

function MetricsTab() {
    const [kpis, setKPIs] = useState();

    useEffect(() => {
        const fetchKPIs = async () => {
            try {
                const kpis = await fetch("/api/reporting/kpi/");
                setKPIs(kpis);
            } catch (xhr) {
                toast.error(parseErrorResponse(xhr));
            }
        };

        fetchKPIs();
    }, []);

    if (!kpis) {
        return <>Loading...</>;
    }

    const count_users = kpis.count_users || 0;
    const count_users_past_24_hours = kpis.count_users_past_24_hours || 0;
    const count_users_past_7_days = kpis.count_users_past_7_days || 0;
    const count_users_past_30_days = kpis.count_users_past_30_days || 0;
    const count_users_past_365_days = kpis.count_users_past_365_days || 0;
    // Land Scrubbing
    const percent_users_never_scrubbed = kpis.percent_users_never_scrubbed || 0;
    const percent_users_below_monthly_scrub_target =
        kpis.percent_users_below_monthly_scrub_target || 0;
    const percent_users_not_scrub_activated =
        kpis.percent_users_not_scrub_activated || 0;
    const avg_scrubs_all_time = kpis.avg_scrubs_all_time || 0;
    const avg_speed_to_first_scrub = kpis.avg_speed_to_first_scrub || 0;
    const avg_speed_to_scrub_activation = kpis.avg_speed_to_scrub_activation || 0;
    // Skip Tracing
    const percent_users_never_skipped = kpis.percent_users_never_skipped || 0;
    const percent_users_below_monthly_skip_target =
        kpis.percent_users_below_monthly_skip_target || 0;
    const percent_users_not_skip_activated = kpis.percent_users_not_skip_activated || 0;
    const avg_skips_all_time = kpis.avg_skips_all_time || 0;
    const avg_speed_to_first_skip = kpis.avg_speed_to_first_skip || 0;
    const avg_speed_to_skip_activation = kpis.avg_speed_to_skip_activation || 0;
    // Parcel Export
    const percent_users_never_exported = kpis.percent_users_never_exported || 0;
    const percent_users_below_monthly_export_rows_target =
        kpis.percent_users_below_monthly_export_rows_target || 0;
    const percent_users_not_export_activated =
        kpis.percent_users_not_export_activated || 0;
    const avg_export_rows_all_time = kpis.avg_export_rows_all_time || 0;
    const avg_speed_to_first_export = kpis.avg_speed_to_first_export || 0;
    const avg_speed_to_export_activation = kpis.avg_speed_to_export_activation || 0;

    return (
        <>
            <h5>Customer KPIs</h5>
            <div className="row">
                <Card name="Total Paying Users" value={formatNumber(count_users)} />
                <Card
                    name="New Customers"
                    today={formatNumber(count_users_past_24_hours)}
                    thisWeek={formatNumber(count_users_past_7_days)}
                    thisMonth={formatNumber(count_users_past_30_days)}
                    thisYear={formatNumber(count_users_past_365_days)}
                    columns={8}
                />
            </div>
            <h5>Scrubbing KPIs</h5>
            <div className="row">
                <Card
                    name="Users Never Scrubbed"
                    value={formatPercent(percent_users_never_scrubbed)}
                />
                <Card
                    name="Users Below Monthly Target (2000)"
                    value={formatPercent(percent_users_below_monthly_scrub_target)}
                />
                <Card
                    name="Users Not Activated"
                    value={formatPercent(percent_users_not_scrub_activated)}
                />
            </div>
            <div className="row">
                <Card
                    name="AVG Total Scrubs / User"
                    value={formatNumber(avg_scrubs_all_time)}
                />
                <Card
                    name="Speed To First Scrub"
                    value={`${formatNumber(avg_speed_to_first_scrub)} ${pluralize(
                        "day",
                        avg_speed_to_first_scrub,
                    )}`}
                />
                <Card
                    name="Speed To Activation (5000)"
                    value={`${formatNumber(avg_speed_to_scrub_activation)} ${pluralize(
                        "day",
                        avg_speed_to_scrub_activation,
                    )}`}
                />
            </div>
            <h5>Skipping KPIs</h5>
            <div className="row">
                <Card
                    name="Users Never Skipped"
                    value={formatPercent(percent_users_never_skipped)}
                />
                <Card
                    name="Users Below Monthly Target (2000)"
                    value={formatPercent(percent_users_below_monthly_skip_target)}
                />
                <Card
                    name="Users Not Activated"
                    value={formatPercent(percent_users_not_skip_activated)}
                />
            </div>
            <div className="row">
                <Card
                    name="AVG Total Skips / User"
                    value={formatNumber(avg_skips_all_time)}
                />
                <Card
                    name="Speed To First Skip"
                    value={`${formatNumber(avg_speed_to_first_skip)} ${pluralize(
                        "day",
                        avg_speed_to_first_skip,
                    )}`}
                />
                <Card
                    name="Speed To Activation (5000)"
                    value={`${formatNumber(avg_speed_to_skip_activation)} ${pluralize(
                        "day",
                        avg_speed_to_skip_activation,
                    )}`}
                />
            </div>
            <h5>Data Platform KPIs</h5>
            <h6>A-La-Carte Users</h6>
            <div className="row">
                <Card
                    name="Users Never Exported"
                    value={formatPercent(percent_users_never_exported)}
                />
                <Card
                    name="Users Below Monthly Target (2000)"
                    value={formatPercent(
                        percent_users_below_monthly_export_rows_target,
                    )}
                />
                <Card
                    name="Users Not Activated"
                    value={formatPercent(percent_users_not_export_activated)}
                />
            </div>
            <div className="row">
                <Card
                    name="AVG Total Export Rows / User"
                    value={formatNumber(avg_export_rows_all_time)}
                />
                <Card
                    name="Speed To First Export"
                    value={`${formatNumber(avg_speed_to_first_export)} ${pluralize(
                        "day",
                        avg_speed_to_first_export,
                    )}`}
                />
                <Card
                    name="Speed To Activation (5000)"
                    value={`${formatNumber(avg_speed_to_export_activation)} ${pluralize(
                        "day",
                        avg_speed_to_export_activation,
                    )}`}
                />
            </div>
        </>
    );
}

function Card({ name, columns, ...props }) {
    // Card column width is 4/12 by default
    if (!columns) {
        columns = 4;
    }
    return (
        <div className={`col-sm-${columns} mb-4`}>
            <div className="card card-outline">
                <div className="card-body p-3">
                    <h6 className="text-sm text-muted">{name}</h6>
                    <CardValue {...props} />
                </div>
            </div>
        </div>
    );
}

function CardValue({ value, today, thisWeek, thisMonth, thisYear }) {
    if (value) {
        return <h5 className="font-weight-bolder mb-0">{value}</h5>;
    }
    // Special format for New Customers card to breakdown counts over time
    return (
        <div className="row">
            <div className="col">
                <h5 className="font-weight-bolder mb-0">{today}</h5>
                <span className="text-sm text-secondary text-nowrap">today</span>
            </div>
            <div className="col">
                <h5 className="font-weight-bolder mb-0">{thisWeek}</h5>
                <span className="text-sm text-secondary text-nowrap">this week</span>
            </div>
            <div className="col">
                <h5 className="font-weight-bolder mb-0">{thisMonth}</h5>
                <span className="text-sm text-secondary text-nowrap">this month</span>
            </div>
            <div className="col">
                <h5 className="font-weight-bolder mb-0">{thisYear}</h5>
                <span className="text-sm text-secondary text-nowrap">this year</span>
            </div>
        </div>
    );
}

function ScrapingTab() {
    const { user } = useContext(UserContext);

    if (!userHasPerm(user, "view_zytescraperequest")) {
        return "Not Authorized";
    }

    return (
        <div className="col-md-12 mb-5">
            <Scraping />
        </div>
    );
}

function SystemTab() {
    const [openSeats, setOpenSeats] = useState();
    const [celeryTasks, setCeleryTasks] = useState();

    const saveSeats = async (new_seats) => {
        try {
            const data = await post("/api/save_seats/", {
                new_seats: new_seats,
            });
            setOpenSeats(data.seats);
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    const showEditSeats = async () => {
        const result = await Swal.fire({
            title: "Enter new seats number",
            input: "number",
            inputValue: openSeats,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value || value < 0) {
                    return "Please enter a valid seat number";
                }
            },
        });
        if (result.isConfirmed) {
            saveSeats(result.value);
        }
    };

    const fetchOpenSeats = async () => {
        try {
            const data = await fetch("/api/open_seats/");
            setOpenSeats(data.open_seats || 0);
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    const fetchCeleryTasks = async () => {
        try {
            const data = await fetch("/api/celery_tasks/");
            setCeleryTasks(data.tasks_count || 0);
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    useEffect(() => {
        fetchOpenSeats();
        fetchCeleryTasks();
    }, []);

    return (
        <div className="row">
            <div className="col-3 mb-5">
                <div className="card card-outline">
                    <div className="card-body d-flex flex-column align-items-center">
                        <p>Open Seats</p>
                        <h3>{openSeats}</h3>
                        <button
                            className="btn btn-icon bg-gradient-primary d-lg-block mt-3 mb-0"
                            onClick={showEditSeats}
                        >
                            Edit Seats
                            <i
                                className="fas fa-arrow-right ms-1"
                                aria-hidden="true"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>

            <div className="col-3 mb-5">
                <div className="card card-outline">
                    <div className="card-body d-flex flex-column align-items-center">
                        <p>Celery Tasks</p>
                        <h3>{celeryTasks}</h3>
                        <button
                            className="btn btn-icon bg-gradient-primary d-lg-block mt-3 mb-0"
                            onClick={fetchCeleryTasks}
                        >
                            Refresh
                            <i className="fas fa-sync-alt ms-1" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function LandRevenueChart({ title, data, series, className }) {
    if (!series) {
        series = [COUNT_SERIES, REVENUE_SERIES, PROFIT_SERIES];
    }

    const daily = data.daily || {};
    const yearly = data.yearly || [];
    const monthly = data.monthly || [];
    const weekly = data.weekly || [];

    const yearLabels = yearly.map((row) => row.year);
    const yearData = series.map(({ mapValues, ...rest }) => ({
        data: mapValues(yearly),
        borderColor: rest.backgroundColor,
        ...rest,
    }));

    const monthLabels = monthly.map((row) => `${row.year}-${zeroPad(row.month, 2)}`);
    const monthData = series.map(({ mapValues, ...rest }) => ({
        data: mapValues(monthly),
        borderColor: rest.backgroundColor,
        ...rest,
    }));

    const weekLabels = weekly.map((row) => `${row.year}-${zeroPad(row.month, 2)}`);
    const weekData = series.map(({ mapValues, ...rest }) => ({
        data: mapValues(weekly),
        borderColor: rest.backgroundColor,
        ...rest,
    }));

    const dailyLabels = daily.map(
        (row) => `${row.year}-${zeroPad(row.month, 2)}-${zeroPad(row.day, 2)}`,
    );
    const dailyData = series.map(({ mapValues, ...rest }) => ({
        data: mapValues(daily),
        borderColor: rest.backgroundColor,
        ...rest,
    }));

    return (
        <div className={`d-flex flex-column ${className || ""}`}>
            {title && <h5>{title}</h5>}
            <Tabs defaultActiveKey="monthly" className="mb-4">
                <Tab eventKey="daily" title="Daily">
                    <BarChart labels={dailyLabels} datasets={dailyData} />
                </Tab>
                <Tab eventKey="weekly" title="Weekly">
                    <BarChart labels={weekLabels} datasets={weekData} />
                </Tab>
                <Tab eventKey="monthly" title="Monthly">
                    <BarChart labels={monthLabels} datasets={monthData} />
                </Tab>
                <Tab eventKey="yearly" title="Yearly">
                    <BarChart labels={yearLabels} datasets={yearData} />
                </Tab>
            </Tabs>
        </div>
    );
}
