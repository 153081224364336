import { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { AGGrid } from "components";
import { UserContext } from "context";
import {
    ajax_wrapper,
    formatDate,
    getAuthToken,
    parseErrorResponse,
    post,
    saveImpersonationToken,
    userHasPerm,
} from "functions";
import { DeleteButton } from "library";
import { API_URL } from "settings";

function LastLoginStatus({ value }) {
    if (!value) {
        return "";
    }
    const color = getLoginStatusColor(value);
    return (
        <span className="badge badge-dot me-4">
            <i className={`bg-${color}`} />
            <span className="text-dark text-xs">{formatDate(value)}</span>
        </span>
    );
}

function getLoginStatusColor(lastLogin) {
    const now = new Date();
    const lastLoginDate = new Date(lastLogin);

    // Calculate the difference in days
    const diffTime = Math.abs(now - lastLoginDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays >= 30) {
        return "danger";
    } else if (diffDays >= 14) {
        return "warning";
    } else {
        return "success";
    }
}

function ImpersonateUserButton({ value }) {
    const onClick = () =>
        post(`/user/impersonate/`, { id: value })
            .then((data) => {
                saveImpersonationToken(data);
                window.location.href = "/";
            })
            .catch((xhr) => toast.error(parseErrorResponse(xhr)));

    return (
        <button className="btn" onClick={onClick}>
            View As
        </button>
    );
}

function CreditsButton({ value }) {
    return (
        <Link className="btn" to={`/home/admin/user/${value}`}>
            Credits
        </Link>
    );
}

class Checkbox extends Component {
    constructor(props) {
        super(props);

        this.handle_click = this.handle_click.bind(this);
    }

    handle_click() {
        let data = {};
        data[this.props.colDef["field"]] = !this.props.value;

        this.props.handle_click(this.props.data["id"], data);
    }

    render() {
        let extra_class = "";
        if (this.props.value) {
            extra_class = " ag-checked";
        }
        return (
            <div
                onClick={this.handle_click}
                className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ${extra_class}`}
            >
                <input
                    className="ag-input-field-input ag-checkbox-input"
                    type="checkbox"
                />
            </div>
        );
    }
}

export default class UsersTable extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.handle_click = this.handle_click.bind(this);
    }

    handle_click(id, data) {
        ajax_wrapper("POST", `/api/users/${id}/`, data, this.props.refresh_users);
    }

    render() {
        const { user } = this.context;
        let columns = [
            { field: "email", filter: true, pinned: "left" },
            { field: "name", filter: true },
            { field: "phone_number", headerName: "Phone Number", filter: true },
            {
                field: "lia_member",
                headerName: "LIA Member",
                filter: true,
                cellRenderer: Checkbox,
                cellRendererParams: {
                    handle_click: this.handle_click,
                },
            },
            {
                field: "is_friend",
                headerName: "Friend",
                filter: true,
                cellRenderer: Checkbox,
                cellRendererParams: {
                    handle_click: this.handle_click,
                },
            },
            {
                field: "beta",
                filter: true,
                cellRenderer: Checkbox,
                cellRendererParams: {
                    handle_click: this.handle_click,
                },
            },
            {
                field: "stripe_customer_id",
                headerName: "Stripe Customer ID",
                filter: true,
            },
            {
                field: "has_paid_subscription",
                headerName: "Has Paid Subscription",
                filter: true,
            },
            {
                field: "has_unlimited_data",
                headerName: "Has Unlimited Data",
                filter: true,
            },
            {
                field: "stripe_subscription_period_end",
                headerName: "Subscription End",
                valueFormatter: ({ value }) => formatDate(value),
                cellDataType: "dateString",
                filter: true,
            },
            {
                field: "stripe_subscription_canceled_at",
                headerName: "Subscription Canceled",
                valueFormatter: ({ value }) => formatDate(value),
                cellDataType: "dateString",
                filter: true,
            },
            {
                field: "abandoned_cart",
                headerName: "Abandoned Cart",
            },
            { field: "selected_markets", headerName: "Selected Markets" },
            { field: "saved_filters", headerName: "Saved Filters" },
            {
                field: "campaigns_in_progress",
                headerName: "Campaigns In Progress",
            },
            { field: "campaigns_prepared", headerName: "Campaigns Prepared" },
            { field: "campaigns_sent", headerName: "Campaigns Sent" },
            { field: "scrubbed_rows", headerName: "Scrubbed Rows" },
            { field: "skipped_rows", headerName: "Skipped Rows" },
            { field: "exported_rows", headerName: "Exported Rows" },
            { field: "realtor_reports", headerName: "Realtor Reports" },
            {
                field: "last_login",
                headerName: "Last Login",
                cellRenderer: LastLoginStatus,
                filter: true,
                cellDataType: "dateString",
            },
            {
                field: "created_at",
                headerName: "Created At",
                valueFormatter: ({ value }) => formatDate(value),
                filter: true,
                cellDataType: "dateString",
            },
            userHasPerm(user, "impersonate_users") && {
                field: "id",
                headerName: "View",
                cellRenderer: ImpersonateUserButton,
                cellStyle: { textOverflow: "inherit" },
            },
            {
                field: "id",
                headerName: "Credits",
                cellRenderer: CreditsButton,
            },
            {
                field: "id",
                headerName: "Delete",
                cellRenderer: DeleteButton,
                cellRendererParams: {
                    url: `/api/users/`,
                    callback: this.props.refresh_users,
                },
            },
        ];

        // Filter null columns
        columns = columns.filter((col) => col);

        return (
            <>
                <div className="d-flex">
                    <h6 className="flex-fill">Users</h6>
                    <a
                        href={`${API_URL}/user/csv_export/?access_token=${getAuthToken()}`}
                        className="btn btn-secondary"
                    >
                        Export
                    </a>
                </div>
                <AGGrid
                    selectionChanged={this.handle_check_click}
                    rows={this.props.users}
                    columns={columns}
                />
            </>
        );
    }
}
